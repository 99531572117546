@import '~antd/dist/antd.css';

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: 700;
    src: local('Lato'), url(./fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: 900;
    src: local('Lato'), url(./fonts/Lato-Black.ttf) format('truetype');
}

.App {
    font-family: 'Lato', sans-serif;
}

.ant-notification {
    font-family: 'Lato', sans-serif;
}

.ant-notification .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 18px;
}

.ant-notification .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 14px;
}

html {
    font-family: 'Lato', sans-serif;
}

.ant-image-mask-info {
    display: none;
}
